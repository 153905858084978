import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`*`}<strong parentName="p"><em parentName="strong">{`We will have no 4:00pm class at East or 5:30pm at The Ville on
today in order to set up for True Grit.  The last class at East is
10:30am!`}</em></strong></p>
    <p><em parentName="p">{`Last Day of Metcon Week`}</em></p>
    <p>{`100-Double Unders`}</p>
    <p>{`40-Pullups`}</p>
    <p>{`30-Ring Dips`}</p>
    <p>{`20-HSPU’s`}</p>
    <p>{`80 Double Unders`}</p>
    <p>{`35-Pullups`}</p>
    <p>{`25-Ring Dips`}</p>
    <p>{`15-HSPU’s`}</p>
    <p>{`60-Double Unders`}</p>
    <p>{`30-Pullups`}</p>
    <p>{`20-Ring Dips`}</p>
    <p>{`10-HSPU’s`}</p>
    <p>{`40-Double Unders`}</p>
    <p>{`25-Pullups`}</p>
    <p>{`15-Ring Dips`}</p>
    <p>{`5-HSPU’s`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p">{`*`}{`*`}{`**`}</strong>{`True Grit 5, our annual 4 person team competition, will take
place Saturday, November 4th!  Truegritcomp.wordpress.com.  `}<strong parentName="p">{`**`}</strong>{`There
will be no classes or open gym at either location this day.  ***`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.eventbrite.com/e/true-grit-5-tickets-37570622744?aff=es2"
      }}>{`https://www.eventbrite.com/e/true-grit-5-tickets-37570622744?aff=es2`}</a></p>
    <p><strong parentName="p">{`*`}{`This Friday is the last day Louisville East will be open for classes
before merging with The Ville.  The mid moring class currently at East
will move to the Ville.  The following schedule will start next week: `}</strong></p>
    <p><strong parentName="p">{`Sunday:`}</strong>{`\\
`}<strong parentName="p">{`12:30 class`}</strong></p>
    <p><strong parentName="p">{`Monday:`}</strong>{`\\
`}<strong parentName="p">{`6-7 class`}</strong>{`\\
`}<em parentName="p">{`7-10:30 open gym`}</em>{`\\
`}<strong parentName="p">{`10:30-11:30 class`}</strong>{`\\
`}<strong parentName="p">{`12-1 class`}</strong>{`\\
`}<em parentName="p">{`1-4:30 open gym`}</em>{`\\
`}<strong parentName="p">{`4:30-6:30 classes`}</strong>{`\\
`}<em parentName="p">{`6:30-8 open gym`}</em></p>
    <p><strong parentName="p">{`Tuesday:`}</strong>{`\\
`}<strong parentName="p">{`6-7 class`}</strong>{`\\
`}<em parentName="p">{`7-10 open gym`}</em>{`\\
`}<strong parentName="p">{`10-11 class`}</strong>{`\\
`}<em parentName="p">{`11-12 open gym`}</em>{`\\
`}<strong parentName="p">{`12-1 class`}</strong>{`\\
`}<em parentName="p">{`1-4:30 open gym`}</em>{`\\
`}<strong parentName="p">{`4:30-6:30 classes`}</strong>{`\\
`}<em parentName="p">{`6:30-8 open gym`}</em></p>
    <p><strong parentName="p">{`Wednesday:`}</strong>{`\\
`}<strong parentName="p">{`6-7 class`}</strong>{`\\
`}<em parentName="p">{`7-9:30 open gym`}</em>{`\\
`}<strong parentName="p">{`9:30-10:30 class`}</strong>{`\\
`}<em parentName="p">{`10:30-12 open gym`}</em>{`\\
`}<strong parentName="p">{`12-1 class`}</strong>{`\\
`}<em parentName="p">{`1-4:30 open gym`}</em>{`\\
`}<strong parentName="p">{`4:30-6:30 classes`}</strong>{`\\
`}<em parentName="p">{`6:30-8 open gym`}</em></p>
    <p><strong parentName="p">{`Thursday:`}</strong>{`\\
`}<strong parentName="p">{`6-7 class`}</strong>{`\\
`}<em parentName="p">{`7-10 open gym`}</em>{`\\
`}<strong parentName="p">{`10-11 class`}</strong>{`\\
`}<em parentName="p">{`11-12 open gym`}</em>{`\\
`}<strong parentName="p">{`12-1 class`}</strong>{`\\
`}<em parentName="p">{`1-4:30 open gym`}</em>{`\\
`}<strong parentName="p">{`4:30-6:30 classes`}</strong>{`\\
`}<em parentName="p">{`6:30-8 open gym`}</em></p>
    <p><strong parentName="p">{`Friday:`}</strong>{`\\
`}<strong parentName="p">{`6-7 class`}</strong>{`\\
`}<em parentName="p">{`7-10:30 open gym`}</em>{`\\
`}<strong parentName="p">{`10:30-11:30 class`}</strong>{`\\
`}<strong parentName="p">{`12-1 class`}</strong>{`\\
`}<em parentName="p">{`1-4:30 open gym`}</em>{`\\
`}<strong parentName="p">{`4:30-6:30 classes`}</strong>{`\\
`}<em parentName="p">{`6:30-8 open gym`}</em></p>
    <p><strong parentName="p">{`Saturday:`}</strong>{`\\
`}<strong parentName="p">{`9-11 classes`}</strong>{`\\
`}<em parentName="p">{`11-1 Open gym`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      